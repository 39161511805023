import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Support from './components/Support';

import 'gatsby-theme-gaviscon/src/components/Footer/MiddleFooter/MiddleFooter.scss';
import { IMiddleFooter } from 'gatsby-theme-gaviscon/src/components/Footer/MiddleFooter/model';

const MiddleFooter: FC<IMiddleFooter> = ({ sponsors, middleCopyright, image, support }) => {
  const { isMobile, isNotMobile } = useScreenRecognition();

  return (
    <Container fluid>
      <div className="gs-footer-middle">
        {isMobile ? (
          <>
            {support.length ? <Support {...{ support }} /> : null}
            <DangerouslySetInnerHtml
              className="gs-footer-middle__copyright gs-footer-middle__copyright--sm"
              html={middleCopyright}
            />
          </>
        ) : null}
        <div className="gs-footer-middle__wrap">
          <div className="gs-footer-middle__content">
            {isNotMobile ? (
              <>
                {support.length ? <Support {...{ support }} /> : null}
                <DangerouslySetInnerHtml
                  className="gs-footer-middle__copyright gs-footer-middle__copyright--lg"
                  html={middleCopyright}
                />
              </>
            ) : null}
            {sponsors?.length ? (
              <ul className="gs-footer-middle__sponsors">
                {sponsors.map(({ properties: { logo, label } }) => (
                  <li key={logo[0].properties.logo?.fallbackUrl}>
                    {logo[0].properties.logo?.fallbackUrl ? (
                      <GatsbyImage
                        className="gs-footer-middle__sponsor-logo"
                        fluid={logo[0].properties.logo}
                        alt={logo[0].properties.altText}
                      />
                    ) : null}
                    <DangerouslySetInnerHtml
                      className="gs-footer-middle__sponsor-text"
                      html={label}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className="gs-footer-middle__image">
            {image?.fallbackUrl ? (
              <GatsbyImage fluid={image} aria-hidden="true" alt="Footer decoration" />
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default MiddleFooter;
