import React, { FC } from 'react';

import BottomFooter from './BottomFooter';
import TopFooter from './TopFooter';
import MiddleFooter from './MiddleFooter';

import 'gatsby-theme-gaviscon/src/components/Footer/Footer.scss';

const Footer: FC<PageContent.IFooter> = ({
  logo,
  support,
  address,
  navigation,
  bottomLinks,
  copyright,
  middleCopyright,
  sponsors,
  image,
  lsSettings,
}) => (
  <div className="gs-footer">
    <TopFooter {...{ logo, address, navigation, support, lsSettings }} />
    <MiddleFooter {...{ middleCopyright, sponsors, image, support }} />
    <BottomFooter links={bottomLinks} copyright={copyright} />
  </div>
);

export default Footer;
